<template>
  <section>
    <!--
    <cosecha-urgente></cosecha-urgente>
    <screenshot name-pdf="Ingreso Programa"></screenshot>

    <div>
      {{ demand_detail.fields }}
    </div>


-->

    <BrunaForm
      ref="demandDetailRef"
      v-bind:fields="this.demand_detail.fields"
      :card="this.demand_detail.card"
      @change="demandDetailChange"
    ></BrunaForm>

    <BrunaForm
      ref="buyerRef"
      :fields="this.buyer.fields"
      :card="this.buyer.card"
      @change="buyerChange"
    ></BrunaForm>

    <BrunaForm
      ref="orderRef"
      :fields="this.order.fields"
      :card="this.order.card"
      @change="orderChange"
    ></BrunaForm>

    <BrunaFormRepeat
      ref="skuRef"
      :items="this.sku.items"
      :card="this.sku.card"
      @change="skuChange"
    ></BrunaFormRepeat>

    <BrunaForm
      ref="priceProgramRef"
      v-show="demand_detail.fields[1].value == 'program'"
      :fields="this.price_program.fields"
      :card="this.price_program.card"
      @change="priceProgramChange"
    ></BrunaForm>

    <BrunaForm
      ref="priceSpotRef"
      v-show="
        demand_detail.fields[1].value == 'spot' ||
        demand_detail.fields[1].value == 'forecast'
      "
      :fields="this.price_spot.fields"
      :card="this.price_spot.card"
      @change="priceSpotChange"
    ></BrunaForm>

    <b-card>
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>

<script>

import { VueAutosuggest } from 'vue-autosuggest'

import CosechaUrgente from "@/views/clients/camanchaca/components/CosechaUrgente";
import Screenshot from "@/views/clients/camanchaca/components/Screenshot";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
import BrunaFormRepeat from "@/views/brunacomponents/BrunaFormRepeat.vue";

import Vue from 'vue'

import
{
  BRow,
  BCol,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCard,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormDatepicker,
  BCardTitle,
  BCardSubTitle,
  VBTooltip,
  VBPopover
} from "bootstrap-vue";
import vSelect from 'vue-select';
import axios from "@axios";


import useApiServices from '@/services/useApiServices.js';

export default {

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {
    forecast: {
      type: Object,
      default: () => null
    }
  },

  data()
  {

    let distribucion_ton_mensual = []
    let distribucion_ton_semanal = []

    for (let mes = 1; mes <= 12; mes++)
    {
      distribucion_ton_mensual.push(

        {
          label: "Mes " + mes,
          type: "input",
          id: 'mes' + mes,
          rules: 'required|numeric2',
          tooltip: null,
          format: {
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1
          },
          value: null,
          show: false
        },
      );
    }

    for (let semana = 1; semana <= 52; semana++)
    {
      distribucion_ton_semanal.push(

        {
          label: "Semana " + semana,
          type: "input",
          id: 'semana' + semana,
          rules: 'required|numeric2',
          tooltip: null,
          format: {
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: 1
          },
          value: null,
          show: false
        },
      );
    }

    return {

      predictions: [],
      selectedPrediction: null,

      sku_options: [],

      freights: [],
      selectedFreight: null,


      demand_detail: {
        card: {
          title: "Demand Detail",
          subtitle: "The identification of the type of demand (Spot or Program) or type of Contract determines the calculation of the FOBChile price and the fields to be displayed.",
          tooltip: "SPOT-type sales have prices automatically calculated using UB+Tier per customer."
        },
        fields: [

          {
            label: "Office",
            type: "select",
            id: 'office',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona una oficina" },
              { value: "usa_canada", text: "USA & Canada" },
              { value: "mexico_caribbean", text: "Mexico & Caribbean" },
              { value: "japan_korea", text: "Japan & Korea" },
              { value: "chile", text: "Chile" },
              { value: "eu_middle_east", text: "EU & Middle East" },
              { value: "china_sea", text: "China & SEA" },

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Type of Demand",
            type: "select",
            id: 'type_of_demand',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            options: [
              { value: null, text: "Selecciona un tipo" },
              { value: "program", text: "Program" },
              { value: "spot", text: "Spot" },

            ],
            value: null
          },

          {
            label: "Program Type",
            type: "select",
            id: 'program_type',
            rules: 'required',
            options: [
              { value: null, text: "Selecciona un tipo" },
              { value: "fresh", text: "Programa Tipo A" },
              { value: "frozen", text: "Programa Tipo B" },
              { value: "frozen", text: "Programa Tipo C" },

            ],
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



        ]
      },


      buyer: {
        card: {
          title: "Buyer",
          subtitle: "The detail of each buyer comes from the customer master. You can edit here.",
          tooltip: "In the case of Spot sales, each customer has an associated TIER that is used to calculate the sale price"
        },
        fields: [
          {
            label: "Nombre",
            type: "select",
            id: 'nombre',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
            ],
            value: null,
            show: true

          },
          {
            label: "Categoria",
            type: "input",
            id: 'categoria',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            disabled: true,
            value: null,
            show: true
          },
          {
            label: "Economic Interest Group",
            type: "input",
            id: 'economic_interest_group',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            disabled: false,
            value: null,
            show: true
          },
          {
            label: "Brand",
            type: "input",
            id: 'brand',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            disabled: true,
            value: null,
            show: true
          },
          {
            label: "Merchandise Recipient Name",
            type: "input",
            id: 'merchandise_recipient_name',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            disabled: false,
            value: null,
            show: true
          },
          {
            label: "SAP Client to Bill",
            type: "input",
            id: 'sap_client_to_bill',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            disabled: false,
            value: null,
            show: true
          },
          {
            label: "Tier",
            type: "select",
            id: 'tier',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: 0.4, text: "1" },
              { value: 0.2, text: "2" },
              { value: 0.2, text: "3" },
              { value: 0.0, text: "4" },
            ],

            disabled: false,
            value: null,
            show: true
          },

          {
            label: "Reward over UB USD/Kg",
            type: "input",
            id: 'reward_over_ub_usd_kg',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            disabled: true,
            value: null,
            show: true
          },


        ]
      },


      order: {

        card: {
          title: "Order",
          subtitle: "The characterization of the order allows calculating the FOBChile price that is used in the calculation of the RMP.",
          tooltip: ""
        },


        fields: [
          {
            label: "Status Program",
            type: "select",
            id: 'status_program',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "open", text: "Open" },
              { value: "close", text: "Close" }
            ],
            value: null
          },

          {
            label: "Start Date",
            type: "datepicker",
            id: 'start_date',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "End Date",
            type: "datepicker",
            id: 'end_date',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Delivery Type",
            type: "select",
            id: 'delivery_type',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
              { value: "weekly", text: "Weekly" },
              { value: "monthly", text: "Monthly" }
            ],
            value: null
          },



          {
            label: "Country",
            type: "select",
            id: 'country',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            options: [
              { value: null, text: "Selecciona una opcion" },
            ],
            value: null
          },


          {
            label: "Destination Port",
            type: "select",
            id: 'destination_port',
            rules: 'required',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            options: [
              { value: null, text: "Selecciona una opcion" }
            ],
            value: null
          },


          {
            label: "Origen",
            type: "input",
            id: 'origen',
            rules: 'required',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "NP Sap",
            type: "input",
            id: 'np_sap',
            rules: 'required',
            disabled: false,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



        ]
      },

      sku: {

        card: {
          title: "SKU",
          subtitle: "Add all SKUs associated with this sale. If the price is Program, the price to enter is CFR. If the price is SPOT, it is calculated automatically from the Value of the client's UB+Tier.",
          tooltip: ""
        },


        items: [
          {
            id: 1,
            fields: [

              {
                label: "Product Code",
                type: "select",
                id: 'product_code',
                rules: 'required',
                tooltip: null,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },

                options: [{ value: null, text: "Selecciona una opcion" }],
                value: null

              },

              {
                label: "Product Name",
                type: "input",
                id: 'product_name',
                rules: 'required',
                tooltip: null,
                disabled: true,
                format: {
                  xs: 4,
                  sm: 4,
                  md: 4,
                  lg: 4,
                  xl: 4
                },
                value: null
              },

              {
                label: "Representative Trim D Equivalent",
                type: "input",
                id: 'representative_trim_d_equivalent',
                rules: 'required',
                tooltip: null,
                disabled: true,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },

              {
                label: "Quality",
                type: "input",
                id: 'quality',
                rules: 'required',
                tooltip: null,
                disabled: true,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },

              {
                label: "Family",
                type: "input",
                id: 'family',
                rules: 'required',
                tooltip: null,
                disabled: true,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },



              {
                label: "Net Quantity",
                type: "input",
                id: 'net_quantity',
                rules: 'required|numeric2',
                tooltip: null,
                format: {
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1
                },
                value: null
              },


              {
                label: "Unit",
                type: "select",
                id: 'unit',
                rules: 'required',
                tooltip: null,
                format: {
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1
                },
                options: [
                  { value: null, text: "Selecciona una opcion" },
                  { value: "kg", text: "Kg" },
                  { value: "lb", text: "Lb" }
                ],
                value: null
              },

              {
                label: "RC Ajuste Sobrepeso",
                type: "input",
                id: 'r_c_ajuste_sobrepeso',
                rules: 'required',
                tooltip: null,
                disabled: true,
                show: false,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },


              /*
              {
                label: "Overweight",
                type: "select",
                id: 'overweight',
                rules: 'required',
                tooltip: null,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                options: [
                  { value: null, text: "Selecciona una opcion" },
                  { value: 0.00, text: "0%" },
                  { value: 0.01, text: "1%" },
                  { value: 0.02, text: "2%" },
                  { value: 0.03, text: "3%" },
                  { value: 0.04, text: "4%" },
                  { value: 0.05, text: "5%" },
                  { value: 0.06, text: "6%" },
                  { value: 0.07, text: "7%" },
                  { value: 0.08, text: "8%" },
                  { value: 0.09, text: "9%" },
                  { value: 0.10, text: "10%" },
                  { value: 0.11, text: "11%" },
                  { value: 0.12, text: "12%" },
                  { value: 0.13, text: "13%" },
                  { value: 0.14, text: "14%" },
                  { value: 0.15, text: "15%" },
                  { value: 0.16, text: "16%" },
                  { value: 0.17, text: "17%" },
                  { value: 0.18, text: "18%" },
                  { value: 0.19, text: "19%" },
                  { value: 0.20, text: "20%" },
                ],
                value: null
              },
*/

              {
                label: "Overweight (%)",
                type: "input",
                id: 'overweight',
                rules: 'required',
                disabled: true,
                tooltip: null,
                show: false,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },

              {
                label: "Caja",
                type: "input",
                id: 'caja',
                rules: 'required',
                disabled: true,
                tooltip: null,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },


              {
                label: "Kg Finishway",
                type: "input",
                id: 'tons_overweight',
                rules: 'required|numeric2',
                tooltip: null,
                disabled: true,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },


              {
                label: "WFE KG",
                type: "input",
                id: 'wfe_kg',
                rules: 'required|numeric2',
                tooltip: null,
                disabled: true,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },


              {
                label: "FOB OFFICE USD/UNIT",
                type: "input",
                id: 'fob_office_usd_unit',
                rules: 'numeric2',
                tooltip: null,
                disabled: false,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },


              {
                label: "Sale Price",
                type: "input",
                id: 'sale_price',
                rules: 'required|numeric2',
                tooltip: 'For Programs the price is CFR Destination Port',
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },

              {
                label: "Currency Sale Price",
                type: "select",
                id: 'currency_sale_price',
                rules: 'required',
                tooltip: null,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                options: [
                  { value: null, text: "Selecciona una opcion" },
                  { value: "usd", text: "USD" },
                  { value: "eu", text: "EU" },
                  { value: "clp", text: "CLP" }
                ],
                value: null
              },


              {
                label: "USD Exchange Rate",
                type: "input",
                id: 'usd_exchange_rate',
                rules: 'required|numeric2',
                disabled: true,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },


              {
                label: "Freight",
                type: "input",
                id: 'freight',
                rules: 'required|numeric2',
                disabled: true,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },

              {
                label: "Shipping Costs USD/Kg",
                type: "input",
                id: 'shipping_costs',
                rules: 'required|numeric2',
                tooltip: 'Program: Freight; Spot: freight + commissions + expenses',
                disabled: true,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },


              {
                label: "FOB Chile USD/Kg",
                type: "input",
                id: 'fob_chile_usd_kg',
                rules: 'required|numeric2',
                disabled: true,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },


              {
                label: "RMP USD/Kg",
                type: "input",
                id: 'rmp_usd_kg',
                rules: 'required|numeric2',
                disabled: true,
                format: {
                  xs: 2,
                  sm: 2,
                  md: 2,
                  lg: 2,
                  xl: 2
                },
                value: null
              },


              ...distribucion_ton_mensual,

              ...distribucion_ton_semanal


            ]
          }
        ],


      },


      price_program: {

        card: {
          title: "Program Total",
          subtitle: "",
          tooltip: ""

        },

        fields: [

          {
            label: "Total Contract Kg Finishway",
            type: "input",
            id: 'total_contract_overweight_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Total Contract WFE KG",
            type: "input",
            id: 'total_contract_wfe_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Total Shipping Cost USD/Kg",
            type: "input",
            id: 'total_shipping_cost_usd',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Total Contract Value USD FOB CHILE",
            type: "input",
            id: 'total_contract_value_usd_fob_chile',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "FOB Chile Weighted USD/KG",
            type: "input",
            id: 'fob_chile_weighted_usd_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "RMP Weighted USD/KG WFE",
            type: "input",
            id: 'rmp_weighted_usd_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },






        ]
      },


      price_spot: {

        card: {
          title: "Spot/Forecast Price",
          subtitle: "",
          tooltip: ""
        },


        fields: [

          {
            label: "Total Contract KG",
            type: "input",
            id: 'total_contract_overweight_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Total Contract WFE KG",
            type: "input",
            id: 'total_contract_wfe_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Total Shipping Cost USD",
            type: "input",
            id: 'total_shipping_cost_usd',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "Total Contract Value USD FOB CHILE",
            type: "input",
            id: 'total_contract_value_usd_fob_chile',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "FOB Chile Weighted USD/KG",
            type: "input",
            id: 'fob_chile_weighted_usd_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },

          {
            label: "RMP Weighted USD/KG",
            type: "input",
            id: 'rmp_weighted_usd_kg',
            rules: 'required|numeric2',
            disabled: true,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },



        ]
      },




    };
  },
  components: {
    BRow,
    BCol,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BCard,
    BCardBody,
    CosechaUrgente,
    Screenshot,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormDatepicker,
    ToastificationContent,
    vSelect,
    BCardTitle,
    BCardSubTitle,
    VBPopover,
    VBTooltip,
    VueAutosuggest,
    BrunaForm,
    BrunaFormRepeat
  },

  created()
  {

    this.loadLastPrediction()
    this.loadSkus()
    this.loadBuyers()
    this.loadCountries()
    this.loadFreights()



  },

  mounted()
  {




  },


  methods: {

    findSkuByCode(sku_options, code)
    {

      return sku_options.find(function (sku)
      {
        if (sku.value)
        {
          return sku.value.codigo == code;
        } else
        {
          return false
        }

      })

    },


    findFreight(country_destination, port_destination)
    {
      return this.freights.find(freight => freight.meta_data.country_destination == country_destination && freight.meta_data.port_destination == port_destination)

    },

    loadFreights()
    {
      useApiServices.getFreights(this).then((response) =>
      {

        this.freights = response.data;

      });

    },

    loadLastPrediction()
    {

      useApiServices.getLastPrediction(this).then((response) =>
      {
        let values = response.data.prediction_values

        let out = values.map(value =>
        {
          value.meta_data.parsed_date = new Date(value.meta_data.fecha)
          return value.meta_data;
        })

        let ordenado = out.sort(function (a, b)
        {
          return new Date(a.parsed_date) - new Date(b.parsed_date);
        });

        this.predictions = ordenado

      })
        .catch((error) =>
        {
          console.log(error);

        });


    },

    loadSkus()
    {
      useApiServices.getSkus(this).then((response) =>
      {
        this.sku_options = [
          { value: null, text: "Selecciona una opcion" }
        ]


        response.data.forEach(item =>
        {

          this.sku_options.push({
            value: {
              id: item.id,
              codigo: item.meta_data['1_codigo'],
              r_c_ajuste_sobrepeso: item.meta_data['24_r_c_ajuste_sobrepeso'],
              product_name: item.meta_data['8_descripcion'],
              representative_trim_d_equivalent: item.meta_data['38_calibre_trim_d_eq'],
              quality: item.meta_data['10_calidad'],
              family: item.meta_data['6_familia'],
              caja: item.meta_data['17_caja'],
              tons_overweight: 0,
              c_dist: item.meta_data['2_c_dist'],
              c_proceso_total: item.meta_data['4_c_proceso_total'],
              sobrepeso_factor: item.meta_data['26_sobrepeso_factor'],
              c_emp: item.meta_data['3_c_emp'],
              aporte_neto_total_wfe_us_kgwfe: item.meta_data['37_aporte_neto_total_wfe_us_kgwfe'],

            },
            text: item.meta_data['1_codigo']
          })

        });


        this.findFieldByName(this.sku.items[0].fields, 'product_code').options = this.sku_options


        /*
        if (this.forecast)
        {
          console.log({ forecast: this.forecast })

          let codigo = this.forecast.meta_data.sku.product_code.codigo;
          let net_quantity = this.forecast.meta_data.sku.net_quantity;
          let unit = this.forecast.meta_data.sku.unit;

          let sku = this.findSkuByCode(this.sku_options, codigo)

          this.findFieldByName(this.sku.items[0].fields, 'product_code').value = sku.value
          this.findFieldByName(this.sku.items[0].fields, 'product_code').disabled = true

          this.findFieldByName(this.sku.items[0].fields, 'net_quantity').value = net_quantity
          this.findFieldByName(this.sku.items[0].fields, 'net_quantity').disabled = false

          this.findFieldByName(this.sku.items[0].fields, 'unit').value = unit
          this.findFieldByName(this.sku.items[0].fields, 'unit').disabled = true



        }
*/

      })
        .catch((error) =>
        {
          console.log(error);

        });



    },

    loadCountries()
    {


      useApiServices.getCountries(this).then((response) =>
      {
        let countries_options = [
          { value: null, text: "Selecciona una opcion" }
        ]

        response.data.forEach(item =>
        {

          item.origen = 'cl'
          item.np_sap = "cl"

          countries_options.push(
            {
              value: item, text: item.name
            })

        });

        this.findFieldByName(this.order.fields, 'country').options = countries_options;


      })
        .catch((error) =>
        {
          console.log(error);

        });





    },

    loadBuyers()
    {

      useApiServices.getBuyers(this).then((response) =>
      {
        let buyers_options = [
          { value: null, text: "Selecciona una opcion" }
        ]

        response.data.forEach(item =>
        {

          buyers_options.push({
            value: {
              id: item.id,
              name: item.meta_data['name'],
              categoria: item.meta_data['categoria'],
              economic_interest_group: item.meta_data['economic_interest_group'],
              brand: item.meta_data['brand'],
              merchandise_recipient_name: item.meta_data['merchandise_recipient_name'],
              sap_client_to_bill: item.meta_data['sap_client_to_bill'],
              tier: item.meta_data['tier']

            },
            text: item.meta_data['name']
          })

        });


        this.findFieldByName(this.buyer.fields, 'nombre').options = buyers_options;

      })
        .catch((error) =>
        {
          console.log(error);

        });




    },

    findPrediction(date)
    {
      for (let i = 0; i < this.predictions.length - 1; i++)
      {
        let current = this.predictions[i];
        let next = this.predictions[i + 1];

        if (current.parsed_date <= date && date <= next.parsed_date)
        {
          let rmp_ub_2_3 = (current.rmp_ub_2_3 + next.rmp_ub_2_3) / 2
          let rmp_ub_3_4 = (current.rmp_ub_3_4 + next.rmp_ub_3_4) / 2
          let rmp_ub_4_5 = (current.rmp_ub_4_5 + next.rmp_ub_4_5) / 2

          let ub_1_2 = (current.ub_1_2 + next.ub_1_2) / 2
          let ub_2_3 = (current.ub_2_3 + next.ub_2_3) / 2
          let ub_3_4 = (current.ub_3_4 + next.ub_3_4) / 2
          let ub_4_5 = (current.ub_4_5 + next.ub_4_5) / 2




          return { rmp_ub_2_3, rmp_ub_3_4, rmp_ub_4_5, ub_1_2, ub_2_3, ub_3_4, ub_4_5 }
        }


      }

      throw new Error("Intervalo de predicciones no encontrado");

    },


    formatData()
    {

      return {
        demand_detail: this.demand_detail.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        buyer: this.buyer.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        order: this.order.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        sku: this.sku.items.map(item => item.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {})),
        price_program: this.price_program.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        price_spot: this.price_spot.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        forecast: this.forecast
      }


    },

    rebajarContrato()
    {

      useApiServices.postContratosRebajar(this.formatData(), this)
        .then((response) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `El contrato se ha creado exitosamente.`,
            },
          });


        })
        .catch((error) =>
        {
          let message = error.response.data.message;

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: message,
            },
          });

        });





    },

    createContrato()
    {



      useApiServices.postContratosCreate(this.formatData(), this)
        .then((response) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `El contrato se ha creado exitosamente.`,
            },
          });


        })
        .catch((error) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar el contrato</b>.`,
            },
          });

        });





    },


    save()
    {

      console.log(JSON.stringify(this.formatData(), null, 2))

      let self = this;

      Promise.all([
        this.$refs.demandDetailRef.validate(),
        this.$refs.buyerRef.validate(),
        this.$refs.orderRef.validate(),
        this.$refs.skuRef.validate(),
        this.$refs.priceProgramRef.validate(),
        this.$refs.priceSpotRef.validate(),


      ]).then(([demandDetailValid, buyerValid, orderValid, skuValid, priceProgramValid, priceSpotValid]) =>
      {

        //console.log({ demandDetailValid, buyerValid, orderValid, skuValid, priceProgramValid, priceSpotValid })

        if (demandDetailValid && buyerValid && orderValid && skuValid)
        {
          let type_of_demand = this.findFieldByName(this.demand_detail.fields, 'type_of_demand').value

          if ((type_of_demand == "spot" && priceSpotValid) || (type_of_demand == "program" && priceProgramValid))
          {

 
              this.createContrato()
 

          }





        }


      })


    },

    setVisibilityDistribucionMensual(visible)
    {
      this.sku.items.forEach(item => {

        for (let mes = 1; mes <= 12; mes++)
        {
          let field = this.findFieldByName(item.fields, 'mes' + mes)

          if (field)
          {
            field.show = visible
          }

        }




      })

    },


    setVisibilityDistribucionSemanal(visible)
    {
      this.sku.items.forEach(item => {

        for (let semana = 1; semana <= 52; semana++)
        {
          let field = this.findFieldByName(item.fields, 'semana' + semana)

          if (field)
          {
            field.show = visible
          }

        }


      })




    },


 

    priceProgramChange(data)
    {
    },

    priceSpotChange(data)
    {
    },

    buyerChange(field)
    {
      console.log("buyerChange", field);

      if (field.id == "tier")
      {
        this.findFieldByName(this.buyer.fields, 'reward_over_ub_usd_kg').value = field.value
      }

      if (field.id == "nombre")
      {
        this.findFieldByName(this.buyer.fields, 'categoria').value = field.value.categoria
        this.findFieldByName(this.buyer.fields, 'economic_interest_group').value = field.value.economic_interest_group
        this.findFieldByName(this.buyer.fields, 'brand').value = field.value.brand
        this.findFieldByName(this.buyer.fields, 'merchandise_recipient_name').value = field.value.merchandise_recipient_name
        this.findFieldByName(this.buyer.fields, 'sap_client_to_bill').value = field.value.sap_client_to_bill
        this.findFieldByName(this.buyer.fields, 'tier').value = field.value.tier


      }


      this.calculate();



      //this.form_values.buyer = data;
    },

    orderChange(field)
    {
      console.log("orderChange", field);

      if (field.id == "start_date")
      {

        this.selectedPrediction = this.findPrediction(new Date(field.value))

        console.log({ selectedPrediction: this.selectedPrediction })

      }


      if (field.id == "country")
      {

        let destination_ports_options = [{ value: null, text: "Selecciona una opcion" }];

        field.value.ports.forEach(port =>
        {
          destination_ports_options.push({ value: port, text: port.name })

        })

        this.findFieldByName(this.order.fields, 'destination_port').options = destination_ports_options

      }

      if (field.id == "destination_port")
      {

        let selectedCountry = this.findFieldByName(this.order.fields, 'country').value
        let selectedDestinationPort = this.findFieldByName(this.order.fields, 'destination_port').value

        console.log({ selectedCountry, selectedDestinationPort })

        this.selectedFreight = this.findFreight(selectedCountry.name, selectedDestinationPort.name)

        console.log({ selectedFreight: this.selectedFreight })

      }

      if (field.id == "delivery_type")
      {
        let deliveryType = this.findFieldByName(this.order.fields, 'delivery_type').value

        if (deliveryType == "weekly")
        {
          this.setVisibilityDistribucionMensual(false);
          this.setVisibilityDistribucionSemanal(true);
        }

        if (deliveryType == "monthly")
        {
          this.setVisibilityDistribucionMensual(true);
          this.setVisibilityDistribucionSemanal(false);
        }

      }


      this.calculate();

    },

    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },


    skuChange(item, value, field)
    {
      console.log("skuChange", { item, value, field });

      let exchange_rate_eu_usd = 1.1;
      let exchange_rate_clp_usd = 850;
      let exchange_rate_usd_usd = 1;


      if (field.id == "currency_sale_price")
      {

        if (field.value == "eu")
        {
          this.findFieldByName(item.fields, 'usd_exchange_rate').value = exchange_rate_eu_usd
        }
        else if (field.value == "clp")
        {
          this.findFieldByName(item.fields, 'usd_exchange_rate').value = exchange_rate_clp_usd
        }
        else if (field.value == "usd")
        {
          this.findFieldByName(item.fields, 'usd_exchange_rate').value = exchange_rate_usd_usd
        }
        else { }


      }


      if (field.id == "product_code")
      {
        let representative_trim_d_equivalent = field.value.representative_trim_d_equivalent

        console.log({ representative_trim_d_equivalent })

        this.findFieldByName(item.fields, 'product_name').value = field.value.product_name
        this.findFieldByName(item.fields, 'representative_trim_d_equivalent').value = representative_trim_d_equivalent
        this.findFieldByName(item.fields, 'quality').value = field.value.quality
        this.findFieldByName(item.fields, 'family').value = field.value.family
        this.findFieldByName(item.fields, 'caja').value = field.value.caja
        this.findFieldByName(item.fields, 'r_c_ajuste_sobrepeso').value = field.value.r_c_ajuste_sobrepeso
        this.findFieldByName(item.fields, 'overweight').value = field.value.sobrepeso_factor * 100

      }


      this.calculate();

    },

    calculate()
    {
      let total_contract_overweight_kg = 0
      let total_contract_wfe_kg = 0
      let total_shipping_cost_usd = 0
      let total_contract_value_usd_fob_chile = 0
      let rmp_weighted_usd_kg = 0

      let total_net_quantity = 0

      let exchange_rate_eu_usd = 1.1;
      let exchange_rate_clp_usd = 850;
      let exchange_rate_usd_usd = 1;

      let months = 12
      let semanas = 52

      try
      {
        let start_date = this.findFieldByName(this.order.fields, 'start_date').value
        let end_date = this.findFieldByName(this.order.fields, 'end_date').value

        console.log({ start_date, end_date })

        let start_date_parsed = new Date(start_date)
        let end_date_parsed = new Date(end_date)

        console.log({ start_date_parsed, end_date_parsed })

        let days_diff = (end_date_parsed - start_date_parsed) / (1000 * 60 * 60 * 24)

        months = Math.round(days_diff / 30)
        semanas = Math.round(days_diff / 7)

        if (months <= 0)
        {
          months = 1
        }

        if (semanas <= 0)
        {
          semanas = 1
        }


        console.log({ months, semanas })

      }
      catch (error)
      {
        console.log(error)
      }

      try
      {
        this.sku.items.forEach(item =>
        {
          //console.log(item)

          //Se obtiene el precio UB 

          let representative_trim_d_equivalent = this.findFieldByName(item.fields, 'representative_trim_d_equivalent').value

          let ub_price = 0

          if (this.selectedPrediction)
          {
            if (representative_trim_d_equivalent == "0-1 LB")
            {
              ub_price = this.selectedPrediction.ub_2_3 - 0.4;
            }
            else if (representative_trim_d_equivalent == "1-2 LB")
            {
              ub_price = this.selectedPrediction.ub_1_2
            }
            else if (representative_trim_d_equivalent == "2-3 LB")
            {
              ub_price = this.selectedPrediction.ub_2_3

            }
            else if (representative_trim_d_equivalent == "3-4 LB")
            {
              ub_price = this.selectedPrediction.ub_3_4

            }
            else if (representative_trim_d_equivalent == "4-5 LB")
            {
              ub_price = this.selectedPrediction.ub_4_5

            }
            else if (representative_trim_d_equivalent == "5-6 LB")
            {
              ub_price = this.selectedPrediction.ub_4_5

            }
            else if (representative_trim_d_equivalent == "6-UP")
            {
              ub_price = this.selectedPrediction.ub_4_5

            }
            else if (representative_trim_d_equivalent == "5-UP LB")
            {
              ub_price = this.selectedPrediction.ub_4_5

            }
            else if (representative_trim_d_equivalent == "N/A")
            {
              ub_price = 0
            }
            else
            {
              ub_price = 0
            }


            let reward_over_ub_usd_kg = parseFloat(this.findFieldByName(this.buyer.fields, 'reward_over_ub_usd_kg').value)

            console.log({ ub_price, reward_over_ub_usd_kg })

            if (!isNaN(reward_over_ub_usd_kg))
            {
              this.findFieldByName(item.fields, 'sale_price').value = ub_price + reward_over_ub_usd_kg
              this.findFieldByName(item.fields, 'currency_sale_price').value = 'usd'
              this.findFieldByName(item.fields, 'unit').value = 'lb'

            }

          }

          let selectedSku = this.findFieldByName(item.fields, 'product_code').value

          let net_quantity_kgs = parseFloat(this.findFieldByName(item.fields, 'net_quantity').value)
          let unit = this.findFieldByName(item.fields, 'unit').value
          let overweight = parseFloat(this.findFieldByName(item.fields, 'overweight').value) / 100


          let deliveryType = this.findFieldByName(this.order.fields, 'delivery_type').value

          if(deliveryType == "weekly")
          {
            for (let semana = 1; semana <= 52; semana++)
            {
              if (semana <= semanas)
              {
                this.findFieldByName(item.fields, 'semana' + semana).value = net_quantity_kgs / semanas

              } else
              {
                this.findFieldByName(item.fields, 'semana' + semana).value = 0
              }

            }
          }
          else if(deliveryType == "monthly")
          {
            for (let mes = 1; mes <= 12; mes++)
            {
              if (mes <= months)
              {
                this.findFieldByName(item.fields, 'mes' + mes).value = net_quantity_kgs / months

              } else
              {
                this.findFieldByName(item.fields, 'mes' + mes).value = 0
              }

            }

          }


          if (unit == "lb")
          {
            net_quantity_kgs = net_quantity_kgs * 0.4535
          }
          else if (unit == 'kg')
          {
            net_quantity_kgs = net_quantity_kgs * 1
          }
          else { }

          let tons_overweight = net_quantity_kgs * (1 + overweight)
          let wfe_kg = net_quantity_kgs / selectedSku.r_c_ajuste_sobrepeso

          this.findFieldByName(item.fields, 'tons_overweight').value = tons_overweight
          this.findFieldByName(item.fields, 'wfe_kg').value = wfe_kg

          let currency_sale_price = this.findFieldByName(item.fields, 'currency_sale_price').value
          let sale_price = parseFloat(this.findFieldByName(item.fields, 'sale_price').value)
          let sale_price_usd = 0
          let sale_price_usd_kg = 0

          if (currency_sale_price == 'eu')
          {
            sale_price_usd = (sale_price * exchange_rate_eu_usd)
          }
          else if (currency_sale_price == 'usd')
          {
            sale_price_usd = (sale_price * exchange_rate_usd_usd)
          }
          else if (currency_sale_price == 'clp')
          {
            sale_price_usd = (sale_price * exchange_rate_clp_usd)
          }
          else
          {

          }


          if (unit == "lb")
          {
            sale_price_usd_kg = sale_price_usd * 2.2046
          }
          else if (unit == 'kg')
          {
            sale_price_usd_kg = sale_price_usd
          }
          else { }



          let type_of_demand = this.findFieldByName(this.demand_detail.fields, 'type_of_demand').value
          let program_type = this.findFieldByName(this.demand_detail.fields, 'program_type').value

          let freight = 0

          if (program_type == 'fresh')
          {
            let fresco_usd_kg_net = this.selectedFreight.meta_data.fresco_usd_kg_net


            let caja = this.findFieldByName(item.fields, 'caja').value

            console.log(this.selectedFreight.meta_data)

            if (caja == '10 LB')
            {
              fresco_usd_kg_net = fresco_usd_kg_net * (1 + parseFloat(this.selectedFreight.meta_data.fresh_factor_10_lb_only_usa) / 100)
            } else
            {
              fresco_usd_kg_net = fresco_usd_kg_net * (1 + parseFloat(this.selectedFreight.meta_data.fresh_factor_all_optional) / 100)
            }

            this.findFieldByName(item.fields, 'freight').value = fresco_usd_kg_net


            freight = fresco_usd_kg_net * net_quantity_kgs
          }
          else if (program_type == 'frozen')
          {

            this.findFieldByName(item.fields, 'freight').value = this.selectedFreight.meta_data.congelado_usd_kg_net

            freight = this.selectedFreight.meta_data.congelado_usd_kg_net * net_quantity_kgs
          }
          else
          {

          }





          let shipping_costs = 0

          if (type_of_demand == 'spot' || type_of_demand == 'forecast')
          {
            let commision = 0.025 * sale_price_usd_kg
            let aduana_y_bodega = 0.08 * (net_quantity_kgs * 2.2046)

            shipping_costs = (freight + commision + aduana_y_bodega) / net_quantity_kgs;

          } else if (type_of_demand == 'program')
          {
            shipping_costs = freight / net_quantity_kgs
          }
          else
          {

          }





          let fob_chile_usd_kg = (sale_price_usd_kg - shipping_costs);
          let rmp_usd_kg = ((fob_chile_usd_kg - selectedSku.c_dist - selectedSku.c_proceso_total - selectedSku.c_emp) * selectedSku.r_c_ajuste_sobrepeso) + selectedSku.aporte_neto_total_wfe_us_kgwfe

          this.findFieldByName(item.fields, 'shipping_costs').value = shipping_costs
          this.findFieldByName(item.fields, 'fob_chile_usd_kg').value = fob_chile_usd_kg
          this.findFieldByName(item.fields, 'rmp_usd_kg').value = rmp_usd_kg


          total_contract_overweight_kg += net_quantity_kgs
          total_contract_wfe_kg += wfe_kg
          total_shipping_cost_usd += (shipping_costs * net_quantity_kgs)

          total_contract_value_usd_fob_chile += (net_quantity_kgs * fob_chile_usd_kg)

          rmp_weighted_usd_kg += (wfe_kg * rmp_usd_kg)

          total_net_quantity += net_quantity_kgs

        })


        this.findFieldByName(this.price_program.fields, 'total_contract_overweight_kg').value = total_contract_overweight_kg
        this.findFieldByName(this.price_program.fields, 'total_contract_wfe_kg').value = total_contract_wfe_kg
        this.findFieldByName(this.price_program.fields, 'total_shipping_cost_usd').value = total_shipping_cost_usd / total_net_quantity
        this.findFieldByName(this.price_program.fields, 'total_contract_value_usd_fob_chile').value = total_contract_value_usd_fob_chile
        this.findFieldByName(this.price_program.fields, 'fob_chile_weighted_usd_kg').value = total_contract_value_usd_fob_chile / total_net_quantity
        this.findFieldByName(this.price_program.fields, 'rmp_weighted_usd_kg').value = rmp_weighted_usd_kg / total_contract_wfe_kg


        this.findFieldByName(this.price_spot.fields, 'total_contract_overweight_kg').value = total_contract_overweight_kg
        this.findFieldByName(this.price_spot.fields, 'total_contract_wfe_kg').value = total_contract_wfe_kg
        this.findFieldByName(this.price_spot.fields, 'total_shipping_cost_usd').value = total_shipping_cost_usd / total_net_quantity
        this.findFieldByName(this.price_spot.fields, 'total_contract_value_usd_fob_chile').value = total_contract_value_usd_fob_chile
        this.findFieldByName(this.price_spot.fields, 'fob_chile_weighted_usd_kg').value = total_contract_value_usd_fob_chile / total_net_quantity
        this.findFieldByName(this.price_spot.fields, 'rmp_weighted_usd_kg').value = rmp_weighted_usd_kg / total_contract_wfe_kg


      }
      catch (error)
      {
        console.log(error)
      }



    },

    demandDetailChange(data)
    {
      if (data.id == "type_of_demand")
      {
        if (data.value == "program")
        {
          this.findFieldByName(this.buyer.fields, 'tier').show = false;
          this.findFieldByName(this.buyer.fields, 'reward_over_ub_usd_kg').show = false;


        }
        else if (data.value == "spot" || data.value == "forecast")
        {
          this.findFieldByName(this.buyer.fields, 'tier').show = true;
          this.findFieldByName(this.buyer.fields, 'reward_over_ub_usd_kg').show = true;

        }
        else
        {



        }


      }

      this.calculate();

    },


  },
};
</script>

<style scoped>
.titulo {
  margin-top: 5%;
}
</style>

<style lang="scss">
.card {
  .card-title {
    margin-bottom: 1.5rem !important;
  }

  ::v-deep .card-header {
    .heading-elements {
      position: static;
      cursor: inherit;

      .list-inline {
        display: block;

        li {
          a {
            padding: 0;
          }

          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>